<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-input v-model="params.invoice" outlined label="Número da fatura" type="number" clearable />
        </div>
        <div class="col-6">
          <date-input
            buttons
            outlined
            label="Data da fatura"
            :v-model="params.invoiceDate"
            @update="(value) => (params.invoiceDate = value)"
            clearable
            range
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.invoiceStatus"
            label="Status da fatura"
            outlined
            clearable
            :options="[
              { label: 'Aprovado', value: 4 },
              { label: 'Solicitado', value: 12 },
              { label: 'Pago', value: 5 },
              { label: 'Recusado', value: 11 }
            ]"
          />
        </div>

        <div class="col-6">
          <date-input
            buttons
            outlined
            label="Data de pagamento"
            :v-model="params.paymentDate"
            @update="(value) => (params.paymentDate = value)"
            clearable
            range
          />
        </div>

        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <!-- <q-btn
                        color="positive"
                        label="Buscar"
                        no-caps
                        unelevated
                        class="q-px-md"
                        @click="onFilterCampaigns"
                    /> -->
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderApproveInvoices',
  mixins: [FinancialMixin],
  components: { DateInput },
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      filter: true,
      params: {
        invoice: null,
        invoiceDate: null,
        invoiceStatus: null,
        paymentDate: null
      }
    };
  },

  watch: {
    params: {
      handler() {
        this.onFilterCampaigns();
      },
      deep: true
    }
  },

  methods: {
    clearFilter() {
      this.params = {
        invoice: null,
        invoiceDate: null,
        invoiceStatus: null,
        paymentDate: null
      };
      let filterSelect = [];
      filterSelect.push(`affiliate_id=${this.id}`);
      this.ActionSetFilterInvoicesPayments(filterSelect.join('%26'));
      this.getAffiliateInvoicesPayments(filterSelect.join('%26'));
    },

    onFilterCampaigns() {
      let filterSelect = [];
      filterSelect.push(`affiliate_id=${this.id}`);
      if (this.params.invoice) filterSelect.push(`id=${this.params.invoice}`);
      else if (this.params.invoiceDate) {
        let date = [];

        date['start'] = this.$moment(
          this.stringToDate(this.params.invoiceDate.split(' - ')[0], 'dd/MM/yyyy', '/')
        ).format('YYYY-MM-DD');

        date['end'] = this.$moment(
          this.stringToDate(this.params.invoiceDate.split(' - ')[1], 'dd/MM/yyyy', '/')
        ).format('YYYY-MM-DD');

        filterSelect.push(`created_at={"start":"${date['start']}","end":"${date['end']}"}`);
      }
      if (this.params.invoiceStatus) filterSelect.push(`id_states=[${this.params.invoiceStatus.value}]`);
      if (this.params.paymentDate) {
        let date = [];

        date['start'] = this.$moment(
          this.stringToDate(this.params.paymentDate.split(' - ')[0], 'dd/MM/yyyy', '/')
        ).format('YYYY-MM-DD');

        date['end'] = this.$moment(
          this.stringToDate(this.params.paymentDate.split(' - ')[1], 'dd/MM/yyyy', '/')
        ).format('YYYY-MM-DD');

        filterSelect.push(`payment_day={"start":"${date['start']}","end":"${date['end']}"}`);
        console.log(filterSelect);
      }

      // Needs to change de '&' to %26 to work
      this.ActionSetFilterInvoicesPayments(filterSelect.join('%26'));
      this.getAffiliateInvoicesPayments(filterSelect.join('%26'));
    }
  }
};
</script>

<style></style>
