<template>
  <q-layout>
    <q-page padding>
      <div class="page-header">
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Minhas Faturas</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el label="Painel de Controle" to="/ " />
          <q-breadcrumbs-el label="Minhas Faturas" exact />
        </q-breadcrumbs>
      </div>
      <q-separator spaced />
      <header-invoices :id="getProfiles.affiliate_id"></header-invoices>
      <list-invoices :id="getProfiles.affiliate_id"></list-invoices>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderInvoices from '../../components/affiliate/HeaderInvoices.vue';
import ListInvoices from '../../components/affiliate/ListInvoices.vue';

export default {
  name: 'Invoices',
  components: { HeaderInvoices, ListInvoices },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  }
};
</script>
