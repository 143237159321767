<template>
  <q-table flat bordered :data="invoicesPayments" :columns="columns" row-key="id" :pagination="pagination">
    <template v-slot:body-cell-invoice="props">
      <q-td :props="props">
        <q-btn
          flat
          color="primary"
          @click="showInvoice(props.row.outbound_invoice_uuid)"
          icon="fas fa-file-invoice-dollar"
        />
      </q-td>
    </template>
    <template v-slot:bottom>
      <!-- <pagination-table
                @change="getInvoicesPayments"
                :data="reqPagination"
                :pagination="pagination"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  name: 'ListApproveInvoices',
  mixins: [FinancialMixin],
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getAffiliateInvoicesPayments(
          `page=${this.currentPage}%26`,
          `perPage=${this.pagination.rowsPerPage}%26affiliate_id=${this.id}`
        );
      }
    }
  },

  created() {
    this.getAffiliateInvoicesPayments(null, `%26perPage=${this.pagination.rowsPerPage}%26affiliate_id=${this.id}`);
  },

  data() {
    return {
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'id',
          required: true,
          label: 'ID da Fatura',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'created_at',
          required: true,
          label: 'Data da Fatura',
          align: 'left',
          field: 'invoices_payments_created_at',
          format: (value) => this.formatPaymentDate(value),
          sortable: true
        },
        {
          name: 'invoice',
          required: true,
          label: 'Fatura',
          align: 'left',
          field: 'invoice',
          sortable: true
        },
        {
          name: 'total_commissions',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'total_commissions',
          format: (total_commissions) => `R$ ${total_commissions}`,
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Data de Pagamento',
          align: 'left',
          field: 'payment_day',
          format: (value) => this.formatPaymentDate(value),
          sortable: true
        },

        {
          name: 'id_states',
          required: true,
          label: 'Status',
          align: 'left',
          field: 'id_states',
          format: (state) => this.formatStates(state),
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  methods: {
    formatPaymentDate(date) {
      if (date == null) return 'Sem data definida';
      return this.$moment(date).format('L');
    },

    showInvoice(invoiceHash) {
      let route = this.$router.resolve({
        path: `/affiliate/invoice/${invoiceHash}`
      });
      window.open(route.href, '_blank');
    },

    formatStates(state) {
      switch (state) {
        case 1:
        case '1':
          return 'Pendente';

        case '2':
        case 2:
          return 'Liberado';

        case 3:
        case '3':
          return 'Solicitado';

        case 4:
        case '4':
          return 'Aprovado';

        case 5:
        case '5':
          return 'Pago';

        case 11:
        case '11':
          return 'Recusado';

        case 12:
        case '12':
          // return 'Pré-Aprovado';
          return 'Solicitado';

        default:
          return '-';
      }
    }
  }
};
</script>
